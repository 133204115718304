<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-hover
        v-slot="{ hover }"
        class="mb-8"
      >
        <v-card
          outlined
          :style="{ borderColor: hover ? 'black' : null }"
        >
          <v-subheader>
            Locations
          </v-subheader>
          <v-card-text class="pt-0">
            <m-grid-array
              v-model="value.items"
              item-photo="photo"
              item-title="location"
              item-subtitle="title"
              @edit-item="editItem($event)"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="itemDialog = true"
            >
              Add Location
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-card-text>
    <v-dialog
      v-model="itemDialog"
      max-width="600px"
      scrollable
    >
      <validation-observer
        v-if="itemDialog"
        ref="itemForm"
        v-slot="{ valid }"
        slim
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="itemDialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Location"
            >
              <v-text-field
                v-model="editedItem.location"
                outlined
                label="Location"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Title"
            >
              <v-text-field
                v-model="editedItem.title"
                outlined
                label="Title"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Brands
                </v-subheader>
                <v-card-text class="pt-0">
                  <m-grid-array
                    v-model="editedItem.brands"
                    item-photo="logo"
                    @edit-item="editItemBrand($event)"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    @click="itemDialogBrand = true"
                  >
                    Add Item
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Photo
                </v-subheader>
                <v-card-text class="pt-0">
                  <asset-manager v-model="editedItem.photo" />
                </v-card-text>
              </v-card>
            </v-hover>
            <v-textarea v-model="editedItem.address" auto-grow outlined label="Address" rows="3" />
            <v-text-field v-model="editedItem.phone" outlined label="Phone" />
            <v-text-field v-model="editedItem.fax" outlined label="Fax" />
            <v-text-field v-model="editedItem.email" outlined label="Email" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
      <v-dialog
        v-model="itemDialogBrand"
        max-width="480px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitleBrand }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="itemDialogBrand = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Photo
                </v-subheader>
                <v-card-text class="pt-0">
                  <asset-manager v-model="editedItemBrand.logo" />
                </v-card-text>
              </v-card>
            </v-hover>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="saveBrand"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'MLocationCardsForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    itemDialog: false,
    itemDialogBrand: false,
    editedIndex: -1,
    editedItem: {
      title: '',
      location: '',
      address: '',
      photo: '',
      brands: [],
      phone: '',
      fax: '',
      email: ''
    },
    defaultItem: {
      title: '',
      location: '',
      address: '',
      photo: '',
      brands: [],
      phone: '',
      fax: '',
      email: ''
    },
    editedIndexBrand: -1,
    editedItemBrand: {
      logo: ''
    },
    defaultItemBrand: {
      logo: ''
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Location' : 'Edit Location'
    },
    formTitleBrand() {
      return this.editedIndexBrand === -1 ? 'New Brand' : 'Edit Brand'
    }
  },
  watch: {
    itemDialog(val) {
      val || this.close()
    },
    itemDialogBrand(val) {
      val || this.closeBrand()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.items = JSON.parse(JSON.stringify(this.value.items))
    },
    editItem(item) {
      this.editedIndex = this.value.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.itemDialog = true
    },
    deleteItem(item) {
      const index = this.value.items.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.value.items.splice(index, 1)
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.value.items[this.editedIndex], this.editedItem)
      } else {
        this.value.items = [...this.value.items, this.editedItem]
      }
      this.itemDialog = false
    },
    editItemBrand(item) {
      this.editedIndexBrand = this.editedItem.brands.indexOf(item)
      this.editedItemBrand = Object.assign({}, item)
      this.itemDialogBrand = true
    },
    closeBrand() {
      setTimeout(() => {
        this.editedItemBrand = Object.assign({}, this.defaultItemBrand)
        this.editedIndexBrand = -1
      }, 300)
    },
    saveBrand() {
      if (this.editedIndexBrand > -1) {
        Object.assign(this.editedItem.brands[this.editedIndexBrand], this.editedItemBrand)
      } else {
        this.editedItem.brands = [...this.editedItem.brands, this.editedItemBrand]
      }
      this.itemDialogBrand = false
    }
  }
}
</script>
